import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'reflexbox'
import { border } from 'styled-system'

const Field = styled(Box)`
  ${border}
  &:focus {
    background-color: #fff;
    outline: none;
  }
`

const Input = ({ children, label, isLongform, ...props }) => (
  <Box as="label" fontSize={[2, 3]} display="block" mb={3}>
    <div>{label}</div>
    <Field
      fontSize={3}
      fontFamily="sans"
      p={3}
      mt={2}
      width="100%"
      border="1px solid"
      borderColor="keyline"
      bg="highlight"
      as={isLongform ? 'textarea' : 'input'}
      minHeight={isLongform && 300}
      {...props}
    >
      {children}
    </Field>
  </Box>
)

export default Input
