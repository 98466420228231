import React from 'react'
import Input from '../components/ui/Input'
import Button from '../components/ui/Button'
import { Box } from 'reflexbox'

const CustomOrderForm = ({ product, ...props }) => (
  <Box {...props}>
    <form
      name="custom-order"
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <Input
        name="name"
        label="Your name"
        type="text"
        placeholder="What should we call you?"
      />
      <Input
        name="email"
        label="Email address"
        type="email"
        placeholder="hello@example.com"
      />
      <Input
        label="Budget"
        name="budget"
        type="text"
        placeholder="How much are you willing to spend?"
      />
      <Input
        label="Tell us what you're looking for"
        type="email"
        name="body"
        isLongform
        placeholder="Feel free to be as vague or as detailed as you like! I'll email you back shortly to discuss the details."
      />
      <input type="hidden" name="referred-product" value={product} />
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="custom-order" />
      <Button as="button" type="submit">
        Make it happen!
      </Button>
    </form>
  </Box>
)

export default CustomOrderForm
