import React from 'react'
import styled from '@emotion/styled'
import { border } from 'styled-system'
import { Box } from 'reflexbox'

const Outer = styled(Box)`
  ${border}
  position: relative;
  transform-style: preserve-3d;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 5px;
    top: 5px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    z-index: -1;
    transform: translateZ(-1px);
  }
`

const Panel = ({ children, ...props }) => (
  <Outer
    as="aside"
    border="1px solid"
    borderColor="keyline"
    {...props}
    p={[1, 3, 4]}
  >
    {children}
  </Outer>
)

export default Panel
