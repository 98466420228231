import React, { useContext, useState, useEffect } from 'react'
import { useQueryParam } from 'gatsby-query-params'
import { graphql } from 'gatsby'

import StoreContext from '~/context/StoreContext'
import SEO from '~/components/seo'
import Container from '../components/ui/Container'
import Heading from '../components/ui/Heading'
import { Flex, Box } from 'reflexbox'
import Text from '../components/ui/core/Text'
import ProductImage from '../components/ui/ProductImage'
import Panel from '../components/ui/Panel'
import TextLink from '../components/ui/TextLink'
import CustomOrderForm from '../components/CustomOrderForm'

const CustomOrder = ({ data }) => {
  const productId = useQueryParam('ref')
  const [selectedProduct, setSelectedProduct] = useState()
  const {
    store: { client },
  } = useContext(StoreContext)

  useEffect(() => {
    if (!productId) {
      return
    }
    client.product.fetch(productId).then(fetchedProduct => {
      const reducedImage = client.image.helpers.imageForSize(
        fetchedProduct.images[0],
        { maxWidth: 200, maxHeight: 100 }
      )
      setSelectedProduct({ ...fetchedProduct, reducedImage })
    })
  }, [client.product, client.image, productId])

  return (
    <>
      <SEO title="Custom order" />
      <Container>
        <Flex justifyContent="center" my={4} flexWrap="wrap">
          <Box width={[1, 1 / 2, 1 / 2]}>
            <Heading mb={4} level={2} as="h1">
              Custom order
            </Heading>
            <Text fontSize={[3, 3, 4]}>
              The best projects are those where I get to collaborate with a
              customer to create something really special. Everything I make is
              designed to last a lifetime, so spending a bit of time up front
              figuring exactly what you need is really worthwhile.
            </Text>
            {!selectedProduct && (
              <Text fontSize={[2, 2, 3]} mb={5}>
                If you know exactly what you want feel free to go all out and
                tell me everything — leather preferences, thread type,
                personalization — go crazy! Need some guidance? don't sweat it!
                Just let me know a rough idea of what you need and we'll go from
                there.
              </Text>
            )}
            {selectedProduct && (
              <Panel mb={4}>
                <Flex>
                  <Box width={[1 / 4]}>
                    <img
                      style={{ maxWidth: '100%' }}
                      alt={selectedProduct.title}
                      src={selectedProduct.reducedImage}
                    />
                  </Box>
                  <Box width={[3 / 4]} pl={3}>
                    So you like our{' '}
                    <TextLink
                      color="blue"
                      to={`product/${selectedProduct.handle}`}
                    >
                      {selectedProduct.title}
                    </TextLink>
                    ? Awesome! we'll send a link with your enquiry. Let us
                    know what you like about it or what you'd like to change and
                    we'll go from there.
                  </Box>
                </Flex>
              </Panel>
            )}
            <CustomOrderForm product={selectedProduct?.title} />
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]} pl={[0, 3, 4, 6]}>
            <ProductImage
              sizes="680px, (max-width: 1024px) 640px, (max-width: 1440px) 1060px"
              fluid={data.one.childImageSharp.fluid}
              mb={4}
            />
            <ProductImage
              sizes="680px, (max-width: 1024px) 640px, (max-width: 1440px) 1060px"
              fluid={data.two.childImageSharp.fluid}
              mb={4}
            />
            <ProductImage
              sizes="680px, (max-width: 1024px) 640px, (max-width: 1440px) 1060px"
              fluid={data.three.childImageSharp.fluid}
              mb={4}
            />
          </Box>
        </Flex>
      </Container>
    </>
  )
}

export const query = graphql`
  query customOrder($productId: String) {
    one: file(relativePath: { eq: "card-holders.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    two: file(relativePath: { eq: "wash-bag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    three: file(relativePath: { eq: "passport-case.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    product: shopifyProduct(id: { eq: $productId }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default CustomOrder
